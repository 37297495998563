import React from "react";
// import { ProfileSection } from "../components/intro/profile";
import Layout from "../components/layout";
import SeoHelmet from "../components/seo";
import { AllTagsSection } from "../components/tags";

const Tags = () => {
  return (
    <>
      <SeoHelmet
        title="태그 사전"
        description={
          `해당 페이지는 해당 블로그의 모든 게시글에 적용된 태그들에 대한 정보를` +
          ` ` +
          `한국어와 영어 태그명을 기준으로 정렬한 결과입니다.`
        }
        pathname="/tags"
      />
      <Layout addDefaultMainAd={true}>
        {/* <ProfileSection /> */}
        <AllTagsSection />
      </Layout>
    </>
  );
};

export default Tags;
