import React from "react";
import { TagDivisionWrapper } from "./wrapper";
import { Caption } from "./caption";
import { TagListContainer } from "./container";
import { Tag } from "./tag";

export const TagDivision = ({ division, language, titleFormat }) => {
  return (
    <TagDivisionWrapper>
      <Caption>{division.caption}</Caption>
      <TagListContainer>
        {division.data.map((tag, idx) => (
          <Tag
            tag={tag}
            language={language}
            titleFormat={titleFormat}
            key={idx}
          />
        ))}
      </TagListContainer>
    </TagDivisionWrapper>
  );
};
