import React from "react";
import { Count } from "./count";
import { TagLink } from "./link";
import { TagName } from "./name";

export const Tag = ({ tag, language, titleFormat }) => {
  return (
    <TagLink to={`/tag/${tag.slug}`} title={titleFormat(tag[language])}>
      <TagName>{tag[language]}</TagName>
      <Count count={tag.count} />
    </TagLink>
  );
};
