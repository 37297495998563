import React from "react";
import { RecentPosts } from "../posts";
import { RecentPostsWrapper } from "./wrapper";

export const RecentPostsStickySection = () => {
  return (
    <RecentPostsWrapper>
      <RecentPosts />
    </RecentPostsWrapper>
  );
};
