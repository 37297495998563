import React from "react";
import { PostLink } from "./link";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PostWrapper } from "./wrapper";
import { ImageWrapper } from "./image/wrapper";
import { PostInfoContainer } from "./container";
import { PostDate } from "./date";
import { PostTitle } from "./title";
import { PostTags } from "./tags";
import { ImageStyles } from "./image/styles";
import { getCategoryInfoDictionary } from "../../../../../utils";

export const RecentPost = ({ article }) => {
  const { slug, frontmatter } = article;
  const { hero_image, hero_image_alt, date, title, category, tags } =
    frontmatter;

  const categoryDic = getCategoryInfoDictionary();

  return (
    <PostWrapper title={title}>
      <PostLink to={`/${slug}`}>
        <ImageWrapper>
          <GatsbyImage
            image={getImage(hero_image)}
            alt={hero_image_alt}
            imgStyle={ImageStyles}
          />
        </ImageWrapper>
        <PostInfoContainer>
          <PostDate>
            {date} | {categoryDic[category].ko}
          </PostDate>
          <PostTitle>{title}</PostTitle>
          <PostTags tags={tags} />
        </PostInfoContainer>
      </PostLink>
    </PostWrapper>
  );
};
