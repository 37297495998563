import styled from "styled-components";
import { H4 } from "../../../../../common";

export const TagName = styled(H4)`
  height: 100%;
  margin-right: 10px;
  line-height: ${({ theme }) => theme.lineHeight.normal};

  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
