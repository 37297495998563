import styled from "styled-components";

export const TagListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  padding-left: 10px;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    padding-left: 0px;
  }
`;
