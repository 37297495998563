import React, { useEffect, useState } from "react";
import { getValidTagInfoDictionary } from "../../../../../../utils";
import { TagIconSolid as TagIcon } from "../../../../icon";
import { PostTagsWrapper } from "./wrapper";

export const PostTags = ({ tags }) => {
  const [loading, setLoading] = useState(true);
  const [tagInfoDict, setTagInfoDict] = useState({});

  useEffect(() => {
    setTagInfoDict(getValidTagInfoDictionary());
    setLoading(false);
  }, []);

  if (loading) return <></>;

  let fullTagString = tags?.map((tagKey) => tagInfoDict[tagKey].ko).join(", ");

  if (fullTagString.length > 28) {
    fullTagString = fullTagString.substring(0, 25) + "...";
  }

  return (
    <PostTagsWrapper>
      <TagIcon size={20} />
      <span>{fullTagString}</span>
    </PostTagsWrapper>
  );
};
