import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { CaptionBox } from "./caption";
import { RecentPost } from "./post";
import { RecentPostsContainer } from "./container";

export const RecentPosts = () => {
  const {
    allMdx: { nodes: articles },
  } = useStaticQuery(RecentFivePostsQuery);
  return (
    <>
      <CaptionBox>최근 글</CaptionBox>
      <RecentPostsContainer>
        {articles.map((node) => (
          <RecentPost key={node.id} article={node} />
        ))}
      </RecentPostsContainer>
    </>
  );
};

const RecentFivePostsQuery = graphql`
  query RecentFivePosts {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 5) {
      nodes {
        frontmatter {
          title
          category
          tags
          date(formatString: "YYYY-MM-DD")
          hero_image {
            childImageSharp {
              gatsbyImageData(width: 150, aspectRatio: 2)
            }
          }
          hero_image_alt
        }
        id
        slug
      }
    }
  }
`;
