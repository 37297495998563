import styled from "styled-components";

export const ImageWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    padding: 10px;

    img {
      height: 75px;
      width: 75px;
    }
  }
`;
