import { Link } from "gatsby";
import styled from "styled-components";

export const PostLink = styled(Link)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(100px, 1fr);

  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 100px 1fr;
  }
`;
