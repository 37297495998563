import React from "react";
import { ToggleLink } from "./link";
import { LanguageText } from "./text";

export const ToggleLanguageButton = ({
  title,
  innerText,
  chosen,
  pathToSortBy,
}) => {
  return (
    <ToggleLink title={title} chosen={chosen} to={pathToSortBy}>
      <LanguageText>{innerText}</LanguageText>
    </ToggleLink>
  );
};
