import { Link } from "gatsby";
import styled from "styled-components";

export const TagLink = styled(Link)`
  display: flex;

  height: 30px;
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 15px;

  margin: 0 10px 10px 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayBorder};
  }
`;
