import styled from "styled-components";
import { Paragraph } from "../../..";

export const PostDate = styled(Paragraph)`
  margin: 0 0 10px 0;
  padding: 0px;
  line-height: ${({ theme }) => theme.lineHeight.big};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.grayThick};
`;
