import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { TagDictionaryWrapper } from "./wrapper";
import { DictionaryContainer } from "./container";
import { TagDivision } from "./division";
import {
  getTagLanguageInfoDictionary,
  isValidLanguageParam,
} from "../../../../utils";
import { LANGUAGE, TO_TAG_BY_ENG, TO_TAG_BY_KOR } from "../../../../constants";

export const TagDictionarySection = () => {
  const [loading, setLoading] = useState(true);
  const [tagDivisionInfos, setTagDivisionInfos] = useState([]);
  const [language, setLanguage] = useState(LANGUAGE.KO);
  const { search } = useLocation();

  const {
    allMdx: { nodes },
  } = useStaticQuery(AllTagDataQuery);

  useEffect(() => {
    if (!isValidLanguageParam(search)) return;
    setLanguage(search.slice(8));
  }, [search]);

  useEffect(() => {
    setTagDivisionInfos(
      getTagLanguageInfoDictionary(
        nodes.map((node) => node.frontmatter.tags),
        language
      )
    );
    setLoading(false);
  }, [nodes, language]);

  if (loading) return <></>;

  return (
    <TagDictionaryWrapper>
      <DictionaryContainer>
        {tagDivisionInfos.map(
          (division, idx) =>
            division.data.length > 0 && (
              <TagDivision
                key={idx}
                division={division}
                language={language}
                titleFormat={
                  language === LANGUAGE.KO ? TO_TAG_BY_KOR : TO_TAG_BY_ENG
                }
              />
            )
        )}
      </DictionaryContainer>
    </TagDictionaryWrapper>
  );
};

const AllTagDataQuery = graphql`
  query AllTagData {
    allMdx {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`;
