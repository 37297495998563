import styled from "styled-components";

export const PostInfoContainer = styled.div`
  padding: 15px 10px;
  color: ${({ theme }) => theme.colors.textColor};

  display: flex;
  flex-direction: column;
  justify-content: center;
`;
