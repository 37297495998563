import styled from "styled-components";

export const CountBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.grayBoxTranslucent};
  border-radius: 5px;

  padding: 5px;
  height: 100%;
  min-width: 20px;
`;
