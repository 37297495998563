import React from "react";
import { RecentPostsStickySection } from "../common/recent-posts/sticky";
import { TagNavigationSection } from "./tag-nav";
import { AllTagsPageWrapper } from "./wrapper";

export const AllTagsSection = () => (
  <AllTagsPageWrapper>
    <TagNavigationSection />
    <RecentPostsStickySection />
  </AllTagsPageWrapper>
);
