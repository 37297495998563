import styled from "styled-components";

export const RecentPostsWrapper = styled.section`
  margin: 0px 20px;

  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 15px;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    margin: 0 20px 0 0;

    position: -webkit-sticky;
    position: sticky;
    top: ${({ theme }) => theme.heightSize.HEADER_HEIGHT_COVERAGE + 5}px;
    align-self: start;
  }
`;
