import styled from "styled-components";

export const PostTagsWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 5px;

  width: 100%;

  line-height: ${({ theme }) => theme.lineHeight.big};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.grayThick};
`;
