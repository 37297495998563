import React from "react";
import { TagNavigationWrapper } from "./wrapper";
import { TagDictionarySection } from "./tag-dictionary";
import { NavigationCaption } from "./nav";

export const TagNavigationSection = () => {
  return (
    <TagNavigationWrapper>
      <NavigationCaption />
      <TagDictionarySection />
    </TagNavigationWrapper>
  );
};
