import React from "react";
import { CountBox } from "./box";
import { CountBoxWrapper } from "./wrapper";

export const Count = ({ count }) => {
  return (
    <CountBoxWrapper>
      <CountBox>{count}</CountBox>
    </CountBoxWrapper>
  );
};
