import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import {
  LANGUAGE,
  SHOW_TAGS_BY_ENG,
  SHOW_TAGS_BY_KOR,
} from "../../../../constants";
import { NavWrapper } from "./wrapper";
import { ToggleSwitchBox } from "./switch";
import { isEn, isKo, isValidLanguageParam } from "../../../../utils";
import { ToggleLanguageButton } from "./toggle";
import { NavContainer } from "./container";

export const NavigationCaption = () => {
  const [language, setLanguage] = useState(LANGUAGE.KO);
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!isValidLanguageParam(search)) return;
    setLanguage(search.slice(8));
  }, [search]);

  return (
    <NavWrapper>
      <NavContainer>
        전체 태그
        <ToggleSwitchBox>
          <ToggleLanguageButton
            innerText="한글"
            title={SHOW_TAGS_BY_KOR}
            chosen={`${isKo(language)}`}
            pathToSortBy={`${pathname}?sortBy=${LANGUAGE.KO}`}
          />
          <ToggleLanguageButton
            innerText="ENG"
            title={SHOW_TAGS_BY_ENG}
            chosen={`${isEn(language)}`}
            pathToSortBy={`${pathname}?sortBy=${LANGUAGE.EN}`}
          />
        </ToggleSwitchBox>
      </NavContainer>
    </NavWrapper>
  );
};
