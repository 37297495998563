import styled from "styled-components";

export const TagDivisionWrapper = styled.div`
  padding: 15px 0px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grayBorder};

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
`;
