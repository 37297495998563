import styled from "styled-components";

export const TagNavigationWrapper = styled.section`
  display: flex;
  flex-direction: column;

  margin: 0px 20px 20px 20px;

  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 15px;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    max-width: ${({
      theme: {
        widthSize: { MAX_SCREEN_WIDTH, RECENT_POSTS_LIST_WIDTH },
      },
    }) => `${MAX_SCREEN_WIDTH - RECENT_POSTS_LIST_WIDTH}px`};

    margin-bottom: 0px;
  }
`;
