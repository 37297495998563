import styled from "styled-components";
import { H3 } from "../../../../common";

export const Caption = styled(H3)`
  padding-left: 10px;
  padding-bottom: 20px;

  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
